/* Extra small devices (phones, 576px and down) */
@media only screen and (max-width: 575.98px) {
  .getStartedButton {
    margin: 15% 1% 15% 1%;
    border-radius: 0%;
    padding: 15px;
    font-weight: bold;
    align-items: center;
    --bs-btn-hover-bg: black;
    width: 100%;
  }

  .headerTitle {
    font-family: "EB Garamond", serif;
    font-size: 50px;
    display: flex;
    justify-content: center;
    margin-top: 1%;
    margin-bottom: 1%;
  }

  .headerSubtitle {
    font-family: "EB Garamond", serif;
    font-size: 25px;
    display: flex;
    justify-content: center;
    margin-bottom: 0.5%;
  }

  .buttonHr {
    width: 100%;
    opacity: 1;
  }
}

/* Small devices (portrait tablets and large phones, 576px and up) */
@media only screen and (max-width: 767.98px) {
  .getStartedButton {
    margin: 15% 1% 15% 1%;
    border-radius: 0%;
    padding: 15px;
    font-weight: bold;
    align-items: center;
    --bs-btn-hover-bg: black;
    width: 100%;
  }

  .headerTitle {
    font-family: "EB Garamond", serif;
    font-size: 50px;
    display: flex;
    justify-content: center;
    margin-top: 1%;
    margin-bottom: 1%;
  }

  .headerSubtitle {
    font-family: "EB Garamond", serif;
    font-size: 25px;
    display: flex;
    justify-content: center;
    margin-bottom: 0.5%;
  }

  .buttonHr {
    width: 100%;
    opacity: 1;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 991.98px) {
  .getStartedButton {
    margin: 15% 1% 15% 1%;
    border-radius: 0%;
    padding: 15px;
    font-weight: bold;
    align-items: center;
    --bs-btn-hover-bg: black;
    width: 100%;
  }

  .headerTitle {
    font-family: "EB Garamond", serif;
    font-size: 50px;
    display: flex;
    justify-content: center;
    margin-top: 1%;
    margin-bottom: 1%;
  }

  .headerSubtitle {
    font-family: "EB Garamond", serif;
    font-size: 25px;
    display: flex;
    justify-content: center;
    margin-bottom: 0.5%;
  }

  .buttonHr {
    width: 100%;
    opacity: 1;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .getStartedButton {
    margin: 5% 1% 12% 1%;
    border-radius: 0%;
    padding: 15px;
    font-weight: bold;
    align-items: center;
    --bs-btn-hover-bg: black;
    width: 100%;
  }

  .headerTitle {
    font-family: "EB Garamond", serif;
    font-size: 50px;
    display: flex;
    justify-content: center;
    margin-top: 1%;
    margin-bottom: 1%;
  }

  .headerSubtitle {
    font-family: "EB Garamond", serif;
    font-size: 25px;
    display: flex;
    justify-content: center;
    margin-bottom: 0.5%;
  }

  .buttonHr {
    width: 25px;
    opacity: 1;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up)
@media only screen and (min-width: 1200px) {
} */