.navBarLink {
  margin-right: 70px;
  margin-top: 15px;
  margin-bottom: 15px;
  font-family: "EB Garamond", serif;
  font-size: 20px;
  text-decoration: none;
  color: gray;
}

.activeNavLink {
  margin-right: 70px;
  margin-top: 15px;
  margin-bottom: 15px;
  font-family: "EB Garamond", serif;
  font-size: 20px;
  font-weight: bold;
  text-decoration: none;
  color: black;
}

.navBarLink:hover {
  color: gray;
}

.activeNavLink:hover {
  color: black;
}

/* xxs screens */
@media only screen and (min-width: 1px) and (max-width: 379.98px) {
  .myNavBar {
    padding: 3vh;
    font-family: "EB Garamond", serif;
  }

  .navBarBrand {
    font-size: 1.1rem;
  }
}

/* xs screens */
@media only screen and (min-width: 380px) and (max-width: 575.98px) {
  .myNavBar {
    padding: 3vh;
    font-family: "EB Garamond", serif;
  }

  .navBarBrand {
    font-size: 1.3rem;
  }
}

@media only screen and (min-width: 576px) and (max-width: 1200px) {
  .myNavBar {
    padding: 3vh;
    font-family: "EB Garamond", serif;
    font-size: 50px;
  }

  .navBarBrand {
    font-size: 2rem;
  }
}
