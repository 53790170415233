.serviceLink {
  padding: 5px;
  border: 1px solid transparent;
}

.serviceLink:hover {
  border: 1px solid white;
  border-radius: 5px;
  transition: border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

a {
  color: white;
  text-decoration: none;
}

a:hover {
  color: white;
}
